import server from "@/utils/http";

// 封装接口
/**
 * 如果是get请求,拼接对象为请求字符串
 * @param {Object} obj - 待拼接的对象
 * @returns {string} - 拼接成的请求字符串
 * Auth:WANGJIAN
 */
export function encodeSearchParams(obj) {
    const params = []
    Object.keys(obj).forEach((key) => {
        let value = obj[key]
        // 如果值为undefined我们将其置空
        if (typeof value === 'undefined') {
            value = ''
        }
        // 对于需要编码的文本（比如说中文）我们要进行编码
        params.push([key, encodeURIComponent(value)].join('='))
    })
    return params.join('&')
}

// 文件上传
export const uploadImage = (msg) => {
    let data = server.post('/api/common/resource/upload', msg)
    return data;
}
//产品导入上传
export const daoruFile = (msg) => {
    let data = server.post('/api/panel/goods/import', msg)
    return data;
}
//产品导入列表
export const daoruFileList = (msg) => {
    const param = encodeSearchParams(msg)
    let data = server.get('/api/panel/goods/import/list/page' + '?' + param)
    return data;
}
//产品导入明细
export const daoruFileListDetail = (msg) => {
    const param = encodeSearchParams(msg)
    let data = server.get('/api/panel/goods/import/detail' + '?' + param)
    return data;
}
// 请求国家
export const queryCountry = (msg) => {
    const param = encodeSearchParams(msg)
    let data = server.get('/api/panel/common/country/list' + '?' + param)
    return data;
}
// 获取国旗下的地区
export const queryArea = (msg) => {
    const param = encodeSearchParams(msg)
    return server.get(`/api/panel/common/region/list?${param}`)

}
// 获取站点信息
export const getWebInfo = (msg) => {
    const param = encodeSearchParams(msg)
    let data = server.get('/api/panel/system/site/info' + '?' + param)
    return data;
}
// 修改站点信息
export const setWebInfo = (msg) => {
    let data = server.post('/api/panel/system/site/info/save', msg)
    return data;
}
// 获取个人信息
export const getMyInfo = (msg) => {
    const param = encodeSearchParams(msg)
    let data = server.get('/api/panel/admin/info' + '?' + param)
    return data;
}
// 修改个人信息
export const setMyInfo = (msg) => {
    let data = server.post('/api/panel/admin/update', msg)
    return data;
}
// 修改密码
export const setPassword = (msg) => {
    let data = server.post('/api/panel/admin/update/password', msg)
    return data;
}
// 商品概览
export const getGoodsView = (msg) => {
    const param = encodeSearchParams(msg)
    let data = server.get('/api/panel/goods/overview' + '?' + param)
    return data;
}
// 获取数据缓存列表
export const getInterface = (msg) => {
    const param = encodeSearchParams(msg)
    let data = server.get('/api/panel/system/cache/data' + '?' + param)
    return data;
}
// 保存缓存数据
export const setInterface = (msg) => {
    let data = server.post('/api/panel/system/cache/refresh', msg)
    return data;
}
// 获取轮播图列表
export const getBannerList = (msg) => {
    const param = encodeSearchParams(msg)
    let data = server.get('/api/panel/system/banner/list/page?' + param)
    return data;
}
// 轮播图详情
export const detailBanner = (msg) => {
    const param = encodeSearchParams(msg)
    let data = server.get('/api/panel/system/banner/detail?' + param)
    return data;
}
// 切换轮播图状态
export const changeBannerStatus = (msg) => {
    let data = server.post('/api/panel/system/banner/switch', msg)
    return data;
}
// 添加轮播图
export const addBanner = (msg) => {
    let data = server.post('/api/panel/system/banner/save', msg)
    return data;
}
// 删除轮播图
export const delectBanner = (msg) => {
    let data = server.post('/api/panel/system/banner/delete', msg)
    return data;
}