import Vue from 'vue'
import Vuex from 'vuex'
import persistedstate from 'vuex-persistedstate'
import { getGoodsView } from '@/api/base'
import { MessageBox, Message } from 'element-ui'
import router from '../router';
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: '',
        menuList: [],
        // 个人信息
        userInfo: {},
        activeIndex1: '',
        activeIndex2: '',
        isCollapse: false,
        secondMenu: [],
        waitCount: 0,
        stopTip: false,
    },
    getters: {
    },
    mutations: {
        // 获取菜单
        getMenusList(state, val) {
            state.menuList = val
        },
        // 获取token
        getToken(state, val) {
            state.token = val
        },
        // 获取个人
        getUser(state, val) {
            state.userInfo = val
        },
        changeAc1(state, val) {
            console.log(11)
            state.activeIndex1 = val
        },
        changeAc2(state, val) {
            state.activeIndex2 = val
        },
        changeColl(state, val) {
            state.isCollapse = val
        },
        changeSecondMenu(state, val) {
            state.secondMenu = val
        },
        // 退出的时候清除
        removeDate(state) {
            state.token = ''
            state.menuList = []
            state.userInfo = {}
            state.activeIndex1 = ''
            state.activeIndex2 = ''
            state.isCollapse = false
            state.secondMenu = []
        },
        changeStop(state,flag) {
            state.stopTip = flag
        },

    },
    actions: {
        getView(context) {
            getGoodsView({}).then(res => {
                if (context.state.stopTip) return false
                console.log(res, '概览',context.state.stopTip)
                if (res.data.waitUpdateCount !== 0) {
                    MessageBox.confirm(`检测到您存在 <span style='color:red;font-weight:600'>${res.data.waitUpdateCount}</span> 件在3天以上未更新的商品,请点击跳转至产品页更新商品!`, '提示', {
                        confirmButtonText: '前往产品页',  //确认按钮的文字显示
                        type: 'warning',
                        center: true, //文字居中显示
                        showCancelButton: (context.state.userInfo.roleIdentity === 2 || context.state.userInfo.super) ? true : true, //不显示取消按钮
                        cancelButtonText: '关闭并本次登录不再提醒',
                        showClose: false, //是否显示右上角的x
                        closeOnClickModal: false, //是否可以点击空白处关闭弹窗
                        dangerouslyUseHTMLString: true
                    }).then(() => {
                        router.push({ path: '/productList', query: { updata: true } });
                        context.commit("changeAc1", "product");
                        context.commit("changeAc2", 'productList');
                        context.commit(
                            "changeSecondMenu",
                            JSON.stringify(context.state.menuList[1].chilren)
                        );
                    }).catch(() => {
                        context.commit("changeStop",true);
                    })
                }
            })
        }
    },
    modules: {
    },
    plugins: [
        new persistedstate({
            storage: window.localStorage
        })
    ]
})
