import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import * as echarts from 'echarts';
import 'view-design/dist/styles/iview.css';
import VueCropper from 'vue-cropper'
import moment from "moment"
import VueLazyload from 'vue-lazyload'
import 'view-design/dist/styles/iview.css';
import { Button, Table ,Modal,Tree,Row,Col,Upload,Icon,Page,Select,Option,Input,Badge} from 'view-design';
Vue.component('Button', Button);
Vue.component('Table', Table);
Vue.component('Modal', Modal);
Vue.component('Tree', Tree);
Vue.component('Row', Row);
Vue.component('Col', Col);
Vue.component('Upload', Upload);
Vue.component('Icon', Icon);
Vue.component('Page', Page);
Vue.component('Select', Select);
Vue.component('Option', Option);
Vue.component('Input', Input);
Vue.component('Badge', Badge);
import '@/assets/iconfont/iconfont.css';
import './assets/iconfont/iconfont.css';
Vue.use(VueCropper)
Vue.use(ElementUI)
Vue.prototype.$echarts = echarts
Vue.prototype.$moment = moment;
Vue.config.productionTip = false
Vue.use(VueLazyload, {
    loading: 'https://www.keaidian.com/uploads/allimg/190424/24110307_8.jpg' // 懒加载默认图片
})
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
