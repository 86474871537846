import axios from 'axios'
import {MessageBox, Message} from 'element-ui'
import store from '@/store/index'
import {Loading} from 'element-ui';

let loadingInstance = {
    close: function () {
    }
};
let t
var count = 0;
axios.defaults.headers.post['Accept-Language'] =
    'zh-CN'
axios.defaults.headers.get['Accept-Language'] =
    'zh-CN'
// 创建axios实例
console.log(process.env.NODE_ENV)
const serve = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? 'https://vd3hy5vjqto6zrhu-panel.weylan-d.com' : 'http://dev.panel.weylan-d.cn',
    timeout: 600000, //超时时间
})
// 上传文件实列
// 请求拦截
serve.interceptors.request.use(config => {
    // 请求添加token请求头
    let token = store.state.token
    if (token) {
        config.headers.token = token
        const headers = config.headers;
        if (config.url === '/api/common/resource/upload') {
            headers['content-type'] = 'multipart/form-data;charset=utf-8'
        } else {
            headers['content-type'] = 'application/json;charset=utf-8'
        }
    }
    count++
    console.log(config.url, 111)
    if (config.url !== '/api/common/captcha' && config.url !== '/api/panel/message/count/unread?' && config.url.indexOf('/api/panel/goods/import/list/page') === -1) {
        if (t) clearTimeout(t)
        loadingInstance = Loading.service({
            lock: true,
            text: '',
            spinner: '',
            background: 'rgba(0, 0, 0, 0.1)'
        })
    }
    return config;
}, error => {
    Promise.reject(error);
})

// 响应拦截
serve.interceptors.response.use(res => {
    // 处理token过期问题
    // 后台定义状态码或者无效token
    count--
    if (count === 0) {
        t = window.setTimeout(() => {
            loadingInstance.close()
        }, 50)
    }
    let code = res.data.code  //状态码
    if (code === 200004) {
        MessageBox('登录状态已过期,请重新登陆', '提示', {
            confirmButtonText: '重新登陆',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            store.commit('removeDate')
            store.commit("changeStop", false)
            location.href = '/login'
        }).catch(() => {

        })
    }
    if (code === 400 || code === 403 || code === 404 || code === 500) {
        Message.error({
            duration: 1000,
            message: res.data.msg
        })
    }
    return res.data
}, error => {
    count--
    if (count === 0) {
        t = window.setTimeout(() => {
            loadingInstance.close()
        }, 50)
    }
    //借口请求,响应错误,一般都是网络问题,进行处理
    console.log('响应错误', error);
    let {
        message
    } = error;
    if (message === 'Network Error') {
        message = '后端连接异常'
    } else if (message.includes('timeout')) {
        message = '系统接口请求超时'
    }
    // Message({
    //     message: message,
    //     type: 'error',
    //     duration: 1000
    // })
    console.error(message)
    Promise.reject(error);
})
export default serve